import { PostDetailResponse } from "@/web-client/api";
import Link from "next/link";
import { FC, useMemo } from "react";
import IconArrowRight from "@/assets/imgs/svg/icon-arrow-right.svg";
import useDevice from "@/hooks/useDevice";
import TeamIcon from "@/features/team/components/TeamIcon";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import AvatarWithLabel from "@/components/AvatarWithLabel";
import { theme } from "tailwind.config";

interface Props {
  post: PostDetailResponse;
}

const PostReference: FC<Props> = ({ post }) => {
  const { isSp, isPc } = useDevice();
  const withProject = useMemo(() => !!post.project, [post]);
  const hasArrow = useMemo(() => {
    if (isPc) return true;

    return !withProject;
  }, [withProject, isPc]);

  const postOwner = useMemo<{
    imgSrc?: string;
    href?: string;
    name?: string;
  }>(() => {
    if (post.maker) {
      return {
        name: post.maker.name,
        imgSrc: post.maker?.icon?.urls?.small || "/imgs/default_maker_icon.png",
        href: `/makers/${post.maker.id}`,
      };
    }

    return {
      imgSrc: post.user?.icon
        ? post.user.icon.urls.small
        : "/imgs/default_user_icon.png",
      name: post.user?.screen_name,
      href: `/users/${post.user?.id}/posts`,
    };
  }, [post]);

  return (
    <article
      className={[
        "relative bg-white p-8 rounded-sm border border-gray-200 transition hover:bg-gray-100",
      ].join(" ")}
    >
      <div
        className={[
          "flex laptop:flex-row laptop:items-center laptop:space-y-0",
        ].join(" ")}
      >
        <div
          className={[
            "flex flex-1",
            "laptop:flex-row laptop:items-center laptop:justify-between laptop:space-y-0",
            "flex-col space-y-12",
          ].join(" ")}
        >
          {/* Project */}
          {post.project && (
            <div className="relative flex space-x-12 z-10 ">
              <LinkAsModal
                entityId={post.project.id}
                entityType={ModalEntityType.Project}
                as={`/projects/${post.project.id}`}
              >
                <a className="flex-shrink-0">
                  <img
                    className="w-64 h-64 object-cover"
                    src={post.project.main_image.urls.small}
                    alt={post.project.title}
                  />
                </a>
              </LinkAsModal>
              <div className="relative flex flex-col z-10">
                <p>
                  <Link
                    href={`/architectures/projects/search?building_type_id=${post.project.building_type.id}`}
                    prefetch={false}
                    className="text-xs hover:underline text-secondary"
                  >
                    {post.project.building_type.name}
                  </Link>
                </p>

                <h1 className="text-sm">
                  <LinkAsModal
                    entityId={post.project.id}
                    entityType={ModalEntityType.Project}
                    as={`/projects/${post.project.id}`}
                  >
                    <a className="hover:underline">{post.project.title}</a>
                  </LinkAsModal>
                </h1>
              </div>
            </div>
          )}
          {/* Project */}

          {isSp && withProject && <hr />}

          <div className="relative z-10 flex items-center">
            {withProject ? (
              <TeamIcon team={post.project.team} />
            ) : (
              <div className="flex items-center space-x-8">
                <span className="text-2xs text-secondary">posted by</span>
                <AvatarWithLabel
                  {...postOwner}
                  imgSrc={postOwner.imgSrc}
                  hasName
                />
              </div>
            )}
          </div>
        </div>

        {hasArrow && (
          <IconArrowRight
            width={28}
            height={28}
            fill={theme.colors.gray[300]}
          />
        )}
      </div>

      {withProject && (
        <LinkAsModal
          entityId={post.project.id}
          entityType={ModalEntityType.Project}
          as={`/projects/${post.project.id}`}
        >
          <a className="block absolute inset-0 mt-0" />
        </LinkAsModal>
      )}
      {!withProject && postOwner.href && (
        <Link
          href={postOwner.href}
          className="block absolute inset-0 mt-0"
        ></Link>
      )}
    </article>
  );
};

export default PostReference;
